/* src/components/PersonalData.css */

/* src/components/PersonalData.css */

.personal-data {
  width: 100%; /* Ensure full width of the container */
  max-width: 100%; /* Remove any inherent Bootstrap card max-width limitations */
  margin-bottom: 20px;
}

.image-placeholder {
  width: 100px;
  height: 100px;
  background-color: #eee;
  border-radius: 50%;
  margin-right: 20px;
}

.edit-profile {
  font-size: 0.9rem;
  color: #0073e6;
  cursor: pointer;
  text-decoration: underline;
}

.email {
  color: #666;
  font-size: 1rem;
  margin-top: 1px;
}

.description-input-field {
  min-height: 60px;
  resize: vertical;
}

.profile-link {
  display: block;
  margin-bottom: 10px;
  color: #0073e6;
  text-decoration: none;
}

.profile-link:hover {
  text-decoration: underline;
}

.resume-section {
  margin-top: 20px;
}