/* src/components/CompanyData.css */

.company-card {
  width: 100%;
  min-width: 100%;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.company-header {
  display: flex;
  align-items: center;
}

.company-logo-placeholder {
  width: 100px;
  height: 100px;
  background-color: #eee;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
  background-size: cover;
  background-position: center;
  margin-right: 20px;
}

.company-header-text {
  flex: 1;
}

.company-description {
  color: #666;
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}