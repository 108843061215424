/* src/components/Login.css */

/* src/components/Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9fafb; /* Optional: adds a light background */
}

.card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensures responsiveness */
  max-width: 400px; /* Limits the card width */
}

.card-body {
  text-align: center;
}

/* Custom button styles if needed */
.btn-outline-secondary {
  color: #000;
  border-color: #000;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #000;
}

.btn-dark {
  background-color: #000;
  border-color: #000;
}

.btn-dark:hover {
  background-color: #333;
}