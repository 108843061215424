/* src/components/JobListing.css */

.job-listing-container {
  width: 60%; /* Occupies middle 3/5ths of the page */
  margin: 0 auto; /* Centers the container */
  padding: 20px;
}

.job-card {
  width: 100%;
  min-width: 100%;
  padding: 20px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.job-title {
  font-size: 2rem;
  font-weight: bold;
}

.company-name {
  font-size: 1.4rem;
  color: #333;
}

.job-meta,
.hiring-manager {
  font-size: 1.1rem;
  color: #666;
}

.job-description {
  font-size: 1rem;
  color: #444;
  line-height: 1.6;
  text-align: left; /* Ensures left justification */
}

.edit-job-button-container {
  margin-top: 20px;
  text-align: center;
}