/* src/components/MainPage.css */

.main-page {
  padding: 60px 15px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Hero Section */
.hero-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.hero-title {
  font-size: 2.5rem;
  font-weight: 800;
  color: #111827;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: #4f5052;
  font-weight: 400;
}

.cta-button {
  background-color: #166a22;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #047622;
  transform: scale(1.05);
}

/* Blocks Section */
.block {
  margin-top: 40px;
}

.block-item {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-icon {
  width: 80px;
  height: auto;
  margin-bottom: 15px;
}

.block-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 10px;
  text-align: center;
}

.block-text {
  font-size: 1rem;
  color: #4b5563;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  max-width: 80%;
  margin: 0 auto;
}