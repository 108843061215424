/* src/components/Person.css */

.person-container {
  padding-top: 40px;
  width: 100%;
}

.person-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.associated-companies-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
  text-align: center;
}

.company-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 20px;
}

.action-buttons .btn {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 20px;
}

